<template>
	<v-card>
		<v-card-title>
			<v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
			<v-spacer></v-spacer>
			<v-btn :color="primaryColor" dark style="margin-right: 10px" @click="getSubscriptionsList()">
				<i :title="$t('common.update')" class="fas fa-sync" />
			</v-btn>

			<!-- DIALOG FOR CREATING SUBSCRIPTIONS  -->
			<v-dialog v-model="createSubscriptions" persistent max-width="50%">
				<template v-slot:activator="{ on, attrs }">
					<v-btn :color="primaryColor" dark v-bind="attrs" v-on="on"> {{ $t('subscriptionList.createSubscription') }} </v-btn>
				</template>
				<v-card>
					<!--<v-toolbar dark :color="primaryColor">
						<v-toolbar-title>{{ $t('subscriptionList.subscriptionToolbar') }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn icon dark @click="handleClose_subscriptionDialog()">
							<i class="fa fa-window-close" />
						</v-btn>
					</v-toolbar>-->
					<v-card-title class="text-h5">
						{{ $t('subscriptionList.subscriptionToolbar') }}
						<v-spacer></v-spacer>
						<v-btn :color="primaryColor" icon @click="handleClose_subscriptionDialog()">
							<i class="fa fa-window-close" />
						</v-btn>
					</v-card-title>
					<div style="padding: 20px">
						<v-form v-model="subscriptionForm" ref="dataSubscriptionForm" style="margin-left: 50px">
							<div>
								<v-radio-group v-model="dataSubscriptionForm.subscriptionType" row>
									<v-radio label="ID" value="id"></v-radio>
									<v-radio label="idPattern" value="idPattern"></v-radio>
								</v-radio-group>
								<!-- ID -->
								<!-- :label="$t('subscriptionList.id')" -->
								<v-text-field
									:label-width="label_widthColumn"
									v-model="dataSubscriptionForm.id"
									style="width: 80%"
									:rules="idRules"
								/>
								<!-- TYPE -->
								<v-text-field
									:label-width="label_widthColumn"
									:label="$t('subscriptionList.type')"
									v-model="dataSubscriptionForm.type"
									style="width: 80%"
									:rules="typeRules"
								/>
								<!-- DESCRIPTION -->
								<v-text-field
									:label-width="label_widthColumn"
									:label="$t('subscriptionList.description')"
									v-model="dataSubscriptionForm.description"
									style="width: 80%"
									:rules="descriptionRules"
								/>
								<!-- NOTIFICATION URL -->
								<v-text-field
									:label-width="label_widthColumn"
									:label="$t('subscriptionList.notificationURL')"
									v-model="dataSubscriptionForm.notificationURL"
									style="width: 80%"
									:rules="notificationURLRules"
									@keydown.enter.prevent="createSubscription"
								/>
								<!-- ACTIONS -->
								<!--<v-btn :disabled="!subscriptionForm" color="success" @click="createSubscription">
									{{ $t('common.create') }}
								</v-btn>-->
							</div>
						</v-form>
					</div>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn :disabled="!subscriptionForm" color="success" @click="createSubscription">{{ $t('common.accept') }}</v-btn>
						<v-btn color="error" @click="handleClose_subscriptionDialog()">{{ $t('common.cancel') }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card-title>
		<!-- DATATABLE -->
		<v-data-table :headers="headers" :items="list" :search="search">
			<template v-slot:item.status="{ item }">
				<div v-if="item.status === 'active'" class="successSubscription">
					{{ item.status }}
				</div>
				<div v-if="item.status === 'failed'" class="errorSubscription">
					{{ item.status }}
				</div>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-btn icon @click="inspectSubscription(item)">
					<i class="fas fa-edit" />
				</v-btn>
				<v-btn icon @click="showDeleteSubscription(item)">
					<i class="fa fa-trash" />
				</v-btn>
			</template>
		</v-data-table>
		<!-- MODAL DIALOG FOR DELETING SUBSCRIPTIONS -->
		<v-dialog persistent v-model="dialogDeleteSubscription" max-width="35%">
			<v-card>
				<v-card-title class="text-h5">
					{{ $t('subscriptionList.deleteSubscriptionDialogQuestion') }}
					<v-spacer></v-spacer>
					<v-btn :color="primaryColor" icon @click="closeDeleteSubscription()">
						<i class="fa fa-window-close" />
					</v-btn>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="deleteSubscriptionConfirm()">{{ $t('common.ok') }}</v-btn>
					<v-btn color="error" @click="closeDeleteSubscription()">{{ $t('common.cancel') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- DIALOG TO SHOW THE SUBSCRIPTION IN JSON FORMAT -->
		<v-dialog persistent v-model="viewSubscriptionsDetailsDialog" max-width="55%">
			<v-card>
				<v-card-title class="text-h5">
					{{ $t('subscriptionList.inspectSubscription') }}
					<v-spacer></v-spacer>
					<v-btn :color="primaryColor" icon @click="handleCloseInspectSubscription()">
						<i class="fa fa-window-close" />
					</v-btn>
				</v-card-title>
				<div style="padding: 20px">
					<vue-json-editor
						v-model="subscriptionDetails"
						:options="options_JsonEditor"
						:plus="false"
						height="500px"
						@error="onErrorForSubscriptions"
					/>
				</div>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" @click="handleCloseInspectSubscription()">{{ $t('common.close') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import { getORIONSubscriptions, createORION_Subscription, deleteSubscriptionById } from '@/api/orion_API';
import VueJsonEditor from '@/components/ui/VueJsonEditor';

export default {
	components: {
		VueJsonEditor
	},
	data() {
		return {
			search: '',
			headers: [
				{
					text: 'ID',
					align: 'start',
					value: 'subject.entities[0].id'
				},
				{
					text: 'ID Pattern',
					align: 'start',
					value: 'subject.entities[0].idPattern'
				},
				{
					text: 'Type',
					value: 'subject.entities[0].type'
				},
				{
					text: 'Description',
					value: 'description'
				},
				{
					text: 'Status',
					value: 'status'
				},
				{
					text: 'URL Notificación',
					value: 'notification.http.url'
				},
				{
					text: 'Actions',
					value: 'actions'
				}
			],
			list: [],
			createSubscriptions: false,
			subscriptionForm: false,
			dataSubscriptionForm: {
				id: '',
				type: '',
				description: '',
				notificationURL: '',
				subscriptionType: ''
			},
			label_widthColumn: '150px',
			requiredText: '',
			idRules: [(v) => !!v || 'ID or idPattern ' + this.requiredText],
			typeRules: [(v) => !!v || 'Type ' + this.requiredText],
			descriptionRules: [(v) => !!v || 'Description ' + this.requiredText],
			notificationURLRules: [
				(v) => !!v || 'URL is required',
				(v) => (v && v.length <= 256) || 'URL must be less than 256 characters' /* eslint-disable-next-line no-useless-escape*/,
				(v) => /https?:[0-9]*\/\/[\w!?/\+\-_~=;\.,*&@#$%\(\)\'\[\]]+/.test(v) || 'URL must be valid'
			],
			subscriptionToDelete: '',
			dialogDeleteSubscription: false,
			viewSubscriptionsDetailsDialog: false,
			subscriptionDetails: null,
			options_JsonEditor: {
				mode: 'code',
				// enableSort: true,
				enableTransform: false,
				// eslint-disable-next-line no-unused-vars
				onEditable: function (node) {
					// now the control is not editable
					return false;
				}
			},
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {},
	watch: {},
	mounted() {},
	created() {
		this.getSubscriptionsList();
		this.commonTranslation();
	},
	methods: {
		// lista de suscripciones
		getSubscriptionsList() {
			console.log('Method for retrieving the list of subscriptions');
			getORIONSubscriptions().then((response) => {
				this.list = response.message;
				console.log('lista de suscripciones');
				console.log(this.list);
			});
		},

		handleClose_subscriptionDialog() {
			this.createSubscriptions = false;
			this.dataSubscriptionForm = {
				id: '',
				type: '',
				description: '',
				notificationURL: '',
				subscriptionType: ''
			};
		},

		// Metodo para algunas traducciones comunes al crear el formulario
		commonTranslation() {
			this.requiredText = ' ' + this.$t('common.required');
		},

		// Crear suscripciones
		createSubscription() {
			const notification = this.buildNotification(this.dataSubscriptionForm);
			console.log('Notification:', notification);
			this.createORION_Subscriptions(notification);
		},

		// Construyo el objeto notification
		buildNotification(data) {
			var notification = {};
			if (data.subscriptionType === 'idPattern') {
				notification = {
					description: data.description,
					subject: {
						entities: [
							{
								idPattern: data.id,
								type: data.type
							}
						]
					},
					notification: {
						http: {
							url: data.notificationURL
						},
						attrsFormat: 'keyValues'
					}
				};
			} else {
				notification = {
					description: data.description,
					subject: {
						entities: [
							{
								id: data.id,
								type: data.type
							}
						]
					},
					notification: {
						http: {
							url: data.notificationURL
						},
						attrsFormat: 'keyValues'
					}
				};
			}

			return notification;
		},

		// Método para enviar la notificación al back
		async createORION_Subscriptions(notification) {
			// eslint-disable-next-line no-unused-vars
			await createORION_Subscription(notification).then(
				// eslint-disable-next-line no-unused-vars
				(response) => {
					this.getSubscriptionsList();
					this.$puiNotify.success(this.$t('subscriptionList.subscriptionCreated'), this.$t('common.success'));
					this.handleClose_subscriptionDialog();
				},
				(error) => {
					this.$puiNotify.error(error, this.$t('common.error'));
				}
			);
		},

		// Método para inspeccionar una suscripción
		inspectSubscription(item) {
			this.viewSubscriptionsDetailsDialog = true;
			this.subscriptionDetails = item;
		},

		// Método para mostrar modal que borra la suscripción
		showDeleteSubscription(item) {
			this.dialogDeleteSubscription = true;
			this.subscriptionToDelete = item.id;
		},

		// Confirm for deleting the image
		deleteSubscriptionConfirm() {
			deleteSubscriptionById(this.subscriptionToDelete)
				// eslint-disable-next-line no-unused-vars
				.then((response) => {
					this.getSubscriptionsList();
					this.$puiNotify.success(this.$t('subscriptionList.deletedSubscription'), this.$t('common.success'));
					this.closeDeleteSubscription();
				})
				// eslint-disable-next-line no-unused-vars
				.catch((error) => {
					this.$puiNotify.error(error, this.$t('common.error'));
					this.closeDeleteSubscription();
				});
		},

		// Close modal that deletes the subscription
		closeDeleteSubscription() {
			this.dialogDeleteSubscription = false;
			this.subscriptionToDelete = '';
		},

		// Error for Json Editor (Subscriptions)
		onErrorForSubscriptions() {
			console.log('error');
		},

		// Cerrar control json editor
		handleCloseInspectSubscription() {
			this.viewSubscriptionsDetailsDialog = false;
			this.subscriptionDetails = '';
		}
	}
};
</script>

<style lang="postcss" scoped>
.successSubscription {
	color: green;
	font-weight: bold;
}
.errorSubscription {
	color: red;
	font-weight: bold;
}
</style>
